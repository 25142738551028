import {Component, Vue} from "vue-property-decorator";
import {Button} from "vant";
import {ComponentDomain} from "@/domain/component-domain";
import MapZoomDomain from "@/domain/map-zoom-domain";
import {AmapUtil} from "@/util/amap-util";
import {ToastUtil} from "@/util/toast-util";
import {BicycleRequest} from "@/request/BicycleRequest";
import {AxiosError, AxiosResponse} from "axios";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {CompanyDispatchInfo} from "@/biz/company-dispatch-Info";
import ValueUtil from "@/util/value-util";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import {BicycleUtil} from "@/util/bicycle-util";

const CENTER_LOCATION_ICON = require('../../../assets/images/icon-center-location.png');
const DISPATCH_QB_BICYCLE_ICON = require('../../../assets/images/icon_dispatch_qj_bicycle.png');
const DISPATCH_QB_PEOPLE_ICON = require('../../../assets/images/icon_dispatch_qj_person.png');
const DISPATCH_MB_BICYCLE_ICON = require('../../../assets/images/icon_dispatch_mobike_bicycle.png');
const DISPATCH_MB_PEOPLE_ICON = require('../../../assets/images/icon_dispatch_mobike_person.png');
const DISPATCH_HB_BICYCLE_ICON = require('../../../assets/images/icon_dispatch_hb_bicycle.png');
const DISPATCH_HB_PEOPLE_ICON = require('../../../assets/images/icon_dispatch_hb_person.png');

class Location {
  public lng!: number;
  public lat!: number;

  constructor(lng: number, lat: number) {
    this.lng = lng;
    this.lat = lat;
  }
}

@Component({
  name: ComponentDomain.REAL_TIME_MAP,
  components: {[Button.name]: Button}
})
export default class RealTimeMap extends Vue {

  public readonly ZOOM = MapZoomDomain.MAP_ZOOM_16;
  private map: any;
  public centerLocation: Location | null = null;
  public markerList: Array<any> = [];
  public curPointMarker: any;
  public firstLocation = true;
  public centerMarker?: any;
  public curCompanyDispatchInfo: CompanyDispatchInfo | null = null;

  constructor() {
    super()
  }

  private created(): void {
    ToastUtil.showLoading('加载中...');
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      this.map = new AMap.Map('container');
      this.map.setZoom(this.ZOOM); //设置地图层级
      this.map.on('complete', () => {
        // 地图图块加载完成后触发
        ToastUtil.hide();
        this.updateLocation();
        console.log('on map load complete');
      });
      this.addMapListeners();
    });
  }

  private mounted() {

  }

  private beforeDestroy() {
    ToastUtil.hide();
    this.removeMapListeners();
    if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  }


  /**
   * 点击定位图标
   */
  public onLocateClick(): void {
    this.updateLocation();
  }

  private updateLocation() {
    if (this.map) {
      // 高德获取定位
      const tempFirstLocation = this.firstLocation;
      this.firstLocation = false;
      AmapUtil.getLocation(tempFirstLocation ? this.map : null, (status: any, result: any) => {
        if (status == 'complete') {
          this.centerLocation = new Location(result.position.lng, result.position.lat);
          this.map.setZoom(this.ZOOM);
          this.map.setCenter([result.position.lng, result.position.lat]);
          this.addCenterMarker();
          this.reqDispatchData();
        } else {
          ToastUtil.showMessage('定位失败');
        }
      });
    }
  }

  public addCenterMarker() {
    if (this.centerLocation && !this.centerMarker) {
      const marker = AmapUtil.getOffetMarker(this.centerLocation.lat, this.centerLocation.lng, CENTER_LOCATION_ICON, [-17, -33]);
      this.map.add(marker);
      this.centerMarker = marker;
    }
  }


  public clearPointMarkers() {
    for (const marker of this.markerList) {
      this.map.remove(marker);
    }
    this.markerList = [];
    this.curPointMarker = null;
    this.curCompanyDispatchInfo = null;
  }


  public mapMoveStart() {

  }

  public mapMove() {
    if (this.map && this.centerMarker) {
      const center = this.map.getCenter(); //获取当前地图中心位置
      this.centerMarker.setPosition([center.lng, center.lat]);
    }
  }

  public mapMoveEnd() {
    const center = this.map.getCenter(); //获取当前地图中心位置
    this.centerLocation = new Location(center.lng, center.lat);
    this.reqDispatchData();
  }

  public addMapListeners() {
    if (this.map) {
      this.map.on('movestart', this.mapMoveStart);
      this.map.on('mapmove', this.mapMove);
      this.map.on('moveend', this.mapMoveEnd);
    }
  }

  public removeMapListeners() {
    if (this.map) {
      this.map.off('movestart', this.mapMoveStart);
      this.map.off('mapmove', this.mapMove);
      this.map.off('moveend', this.mapMoveEnd);
    }
  }

  public reqDispatchData() {
    if (!this.centerLocation) {
      return;
    }
    BicycleRequest.getCompanyDispatchInfos(String(this.centerLocation.lng), String(this.centerLocation.lat))
      .then((response: AxiosResponse) => {

        const companyDispatchInfo = HttpResponseUtil.parseJSONArray(CompanyDispatchInfo, response.data)
        if (companyDispatchInfo) {
          this.clearPointMarkers();
          this.drawMarks(companyDispatchInfo);
        }
      }).catch((error: AxiosError) => {

      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      ToastUtil.showMessage(resError);
    });
  }

  public getLatLng(dispatchPoint: string): Location | null {
    if (!ValueUtil.isStrEmpty(dispatchPoint)) {
      if (dispatchPoint.indexOf(",") >= 0) {
        const latlonArr = dispatchPoint.split(",");
        if (latlonArr.length > 1) {
          const lon = Number(latlonArr[0]);
          const lat = Number(latlonArr[1]);
          return new Location(lon, lat);
        }
      }
    }
    return null;
  }

  private drawMarks(companyDispatchInfo: CompanyDispatchInfo[]) {
    for (const info of companyDispatchInfo) {
      const selected = this.curCompanyDispatchInfo && this.curCompanyDispatchInfo.company_dispatch_id === info.company_dispatch_id;
      this.drawMark(info, selected);
    }
  }

  public getPath(companyType: number, dispatchType: number): string {
    if (companyType === BicycleTypeDomain.COMPANY_DIDI_TYPE) {
      if (dispatchType === BicycleTypeDomain.DISPATCH_TYPE_BICYCLE) {
        return DISPATCH_QB_BICYCLE_ICON;
      } else {
        return DISPATCH_QB_PEOPLE_ICON;
      }
    } else if (companyType === BicycleTypeDomain.COMPANY_HALLO_TYPE) {
      if (dispatchType === BicycleTypeDomain.DISPATCH_TYPE_BICYCLE) {
        return DISPATCH_HB_BICYCLE_ICON;
      } else {
        return DISPATCH_HB_PEOPLE_ICON;
      }
    } else if (companyType === BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
      if (dispatchType === BicycleTypeDomain.DISPATCH_TYPE_BICYCLE) {
        return DISPATCH_MB_BICYCLE_ICON;
      } else {
        return DISPATCH_MB_PEOPLE_ICON;
      }
    }
    return '';
  }

  private drawMark(info: CompanyDispatchInfo, selected: any) {
    const iconPath = this.getPath(info.company_type, info.dispatch_type);
    const icon = new AMap.Icon({
      size: new AMap.Size(60, 60),    // 图标尺寸
      image: iconPath,  // Icon的图像
      imageSize: new AMap.Size(selected ? 60 : 60 * 0.7, selected ? 60 : 60 * 0.7)   // 根据所设置的大小拉伸或压缩图片
    });

    const location = this.getLatLng(info.dispatch_point);
    if (location === null) {
      return;
    }
    // 将 Icon 实例添加到 marker 上:
    const marker = new AMap.Marker({
      position: new AMap.LngLat(location.lng, location.lat),
      icon: icon,
      offset: new AMap.Pixel(-30, -30)
    });

    marker.on('click', (e: any) => {
      //连续选中同一个图标
      if (this.curCompanyDispatchInfo && this.curPointMarker &&
        this.curCompanyDispatchInfo.company_dispatch_id === info.company_dispatch_id) {
        return;
      }
      // 先把之前选中的清除选中效果
      if (this.curCompanyDispatchInfo && this.curPointMarker) {
        this.map.remove(this.curPointMarker);
        const index = this.markerList.indexOf(this.curPointMarker);
        this.markerList.splice(index, 1);
        this.drawMark(this.curCompanyDispatchInfo, false);
      }
      // 清除选中点位未点击效果，增加选中效果
      this.curCompanyDispatchInfo = info;
      this.map.remove(marker);
      const index = this.markerList.indexOf(marker);
      this.markerList.splice(index, 1);
      this.drawMark(info, true);

    });
    this.map.add(marker);
    this.markerList.push(marker);
    if (selected) {
      this.curPointMarker = marker;
    }
  }

  public getCompanyName(companyType: number): string {
    return BicycleUtil.getBicycleCompanyTypeName(companyType);
  }

  public getDispatchType(dispatchType: number): string {
    return BicycleUtil.getDispatchType(dispatchType);
  }

  public getClassBg(companyType: number): string {
    if (BicycleTypeDomain.COMPANY_DIDI_TYPE === companyType) {
      return 'dispatch-icon-qb-bg';
    } else if (BicycleTypeDomain.COMPANY_HALLO_TYPE === companyType) {
      return 'dispatch-icon-hb-bg';
    } else if (BicycleTypeDomain.COMPANY_MOBAI_TYPE === companyType) {
      return 'dispatch-icon-mb-bg';
    }
    return 'dispatch-icon-qb-bg';
  }

  /**
   * 点击确定
   */
  public onSureClick(): void {
    if (!this.curCompanyDispatchInfo) {
      return;
    }
    this.$router.push({
      path: '/supervise-event',
      query: {fromPage: 'real-time-map', data: JSON.stringify(this.curCompanyDispatchInfo)}
    })
  }

  public onTelClick() {
    if (this.curCompanyDispatchInfo) {
      window.location.href = 'tel://' + this.curCompanyDispatchInfo.telephone;
    }
  }
}
