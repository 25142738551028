export class RegionAdministrationAssessStatistics {

  public region_ad_assess_statistics_id!: string;
  public region_code!: string;
  //总分
  public total_score!: number;
  //事件分数
  public event_score!: number;
  //入栏分数
  public entry_score!: number;
  //淤积分数
  public overrun_score!:number;
  //排名
  public rank!: number;
  public created_time!: number;
}
