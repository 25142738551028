import {Component, Vue} from "vue-property-decorator";
import {Cell, Field} from "vant";
import {RankingRequest} from "@/request/ranking-request";
import HttpResErrorPaser, {EntityErrorPaser} from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import {CompanyCooperateDegreeDetail} from "@/biz/company-cooperate-degree-detail";
import TimeUtil from "@/util/time-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import {RegionCodeUtil} from "@/util/region-code-util";
import ValueUtil from "@/util/value-util";
import Error from "@/http/error/Error";

@Component({
  components: {
    [Cell.name]: Cell,
    [Field.name]: Field,
  }
})
export default class CompanyCoordinate extends Vue {

  public regionName = '';
  public timeStr = '';
  public qbCount = '';
  public mbCount = '';
  public hbCount = '';

  public coordinateTotalCount = 10;
  public coordinateTime = '';


  private cooperateDegreeDetail: CompanyCooperateDegreeDetail | null = null;

  mounted() {
    this.getCompanyCordinateDetail();
    this.getCompanyDegreeSetting();
    this.getCompanyTimeSetting();
    this.timeStr = TimeUtil.getDateYYYYMMDD(new Date().getTime());
    this.regionName = RegionCodeUtil.getRegionName(LocalStorageAgent.getUserInfo()!.region_code);
  }


  public onSaveClick() {
    if (ValueUtil.isStrEmpty(this.hbCount) || ValueUtil.isStrEmpty(this.mbCount)
      || ValueUtil.isStrEmpty(this.hbCount)) {
      ToastUtil.showMessage('请输入分数');
      return;
    }
    if (Number(this.qbCount) > this.coordinateTotalCount
      || Number(this.mbCount) > this.coordinateTotalCount
      || Number(this.hbCount) > this.coordinateTotalCount) {
      ToastUtil.showMessage('录入最高分数为' + this.coordinateTotalCount + '分');
      return;
    }
    ToastUtil.showMessage('企业配合度录入中...');
    RankingRequest.createCompanyCooperateDegree(this.qbCount, this.mbCount, this.hbCount).then((response) => {
      ToastUtil.hide();
      this.$router.go(-1);
    }).catch((error) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
        public parseUnprocessableEntity(error: Error): string {
          if (error != null) {
            if ('assessment_time' == error.resource && 'not_allow' == error.code) {
              return '未在考核时间内，不允许创建！';
            }
          }
          return '数据获取失败';
        }
      });
      ToastUtil.showMessage(resError);
    });
  }

  private getCompanyCordinateDetail() {
    RankingRequest.getCompanyCooperateDegreeDetail().then((response) => {
      this.cooperateDegreeDetail = HttpResponseUtil.parseJSONStr(CompanyCooperateDegreeDetail, response.data);
    }).catch((error) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      if (error.response?.status !== 404) {
        ToastUtil.showMessage(resError);
      }
    });
  }

  private getCompanyDegreeSetting() {
    RankingRequest.getCompanyCooperateDegreeSetting().then((response) => {
      this.coordinateTotalCount = response.data.total_score;
    }).catch((error) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });

  }

  private getCompanyTimeSetting() {
    RankingRequest.getCompanyCooperateTimeSetting().then((response) => {
      this.coordinateTime = response.data.start_time + '-' + response.data.end_time;
    }).catch((error) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }
}
