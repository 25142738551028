import {Component, Vue} from 'vue-property-decorator';
import {Button, Field, Form} from 'vant';
import {ToastUtil} from '@/util/toast-util';
import {UserRequest} from '@/request/UserRequest';
import {AxiosError, AxiosResponse} from 'axios';
import HttpResErrorPaser, {EntityErrorPaser} from '@/http/error/HttpResErrorPaser';
import Error from '@/http/error/Error';
import HttpResponseUtil from '@/http/HttpResponseUtil';
import UserInfo from '@/biz/user-info';
import {LocalStorageAgent} from '@/storage/local-storage-agent';

@Component({
  components: {[Button.name]: Button, [Form.name]: Form, [Field.name]: Field}
})

export default class Login extends Vue {
  public username: string = '';
  public password = '';

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
  }

  private mounted() {
    console.log('mounted');
    const lastedAccount = LocalStorageAgent.getLastedAccount();
    if (lastedAccount) {
      this.username = lastedAccount;
    }
  }

  public onSubmit(): void {
    ToastUtil.showLoading('登录中...');
    UserRequest.login(this.username, this.password).then(this.onLoginRequestSuccess).catch(this.onLoginRequestFailed);
  }

  public onLoginRequestSuccess(response: AxiosResponse): void {
    ToastUtil.hide();
    ToastUtil.showMessage('登录成功');
    LocalStorageAgent.setLastedAccount(this.username);
    this.requestUserInfo();
  }

  public onLoginRequestFailed(error: AxiosError): void {
    ToastUtil.hide();
    // 解析422
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
      public parseUnprocessableEntity(error: Error): string {
        if (error != null) {
          if ('password' == error.field && 'invalid' == error.code) {
            return '账号或密码错误！';
          } else if ('username' == error.field && 'invalid' == error.code)
            return '账号或密码错误！'
        }
        return '登陆失败';
      }
    });
    ToastUtil.showMessage(resError);
  }

  private requestUserInfo() {
    UserRequest.getUserInfo().then((response: AxiosResponse) => {
      const userInfo: UserInfo = HttpResponseUtil.parseJSONStr(UserInfo, response.data);
      LocalStorageAgent.setUserInfo(userInfo);
      // 4,统筹监管 5,区监管 6,街道监管 8三方
      if (UserInfo.hasPermission(userInfo.user_types)) {
        this.$router.replace({path: '/main'})
      } else {
        ToastUtil.hide();
        ToastUtil.showMessage('暂无权限')
      }
    }).catch((error: AxiosError) => {
      ToastUtil.hide();
      // 解析422
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      ToastUtil.showMessage(resError);
    });
  }
}
