import {Component, Vue} from "vue-property-decorator";
import {Button} from "vant";
import {ToastUtil} from "@/util/toast-util";
import {AmapUtil} from "@/util/amap-util";
import {WxAgent} from "@/wx/wx-agent";
import MapZoomDomain from "@/domain/map-zoom-domain";
import {BicycleRequest} from "@/request/BicycleRequest";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import ParkingPoint from "@/biz/parking-point";
import {Route} from "vue-router";
import {ComponentDomain} from "@/domain/component-domain";
import {RouterDomain} from "@/domain/router-domain";

const PARKING_ICON = require('../../../assets/images/icon-map-parking.png');
const PARKING_DISABLE_ICON = require('../../../assets/images/icon-map-parking-disable.png');
const CENTER_LOCATION_ICON = require('../../../assets/images/icon-center-location.png');

class Location {
  public lng!: number;
  public lat!: number;

  constructor(lng: number, lat: number) {
    this.lng = lng;
    this.lat = lat;
  }
}

class Address {
  public lng!: number;
  public lat!: number;
  public regionCode!: string;
  public street!: string;
  public province!: string;
  public city!: string;
  public district!: string;
  public township!: string;
  public fullName!: string;
  public simpleName!: string;

  constructor(lng: any, lat: any, regionCode: string, street: string, province: string, city: string, district: string, township: string, fullName: string) {
    this.lng = lng;
    this.lat = lat;
    this.regionCode = regionCode;
    this.street = street;
    this.province = province;
    this.city = city;
    this.district = district;
    this.township = township;
    this.fullName = fullName;
  }
}

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

@Component({
  name: ComponentDomain.LOCATION_SELECTOR,
  components: {[Button.name]: Button}
})

export default class LocationSelector extends Vue {

  public readonly ZOOM = MapZoomDomain.MAP_ZOOM_19;
  private map: any;
  public centerLocation: Location | null = null;
  public parkingPointList: Array<ParkingPoint> = [];
  public markerList: Array<any> = [];
  public polygons: Array<any> = [];
  public curParkingPoint: ParkingPoint | null = null;
  public curPointPolygon: any;
  public curPointMarker: any;
  public isSelectParking = false;
  public address: Address | null = null;
  public isSureClick = false;
  public requestPromise?: any;
  public firstLocation = true;
  public centerMarker?: any;

  constructor() {
    super()
  }

  private created(): void {
    const eventType = Number(this.$route.query.eventType);
    // 5:停放超限, 显示点位，其他不显示点位
    this.isSelectParking = (eventType === 5);
    ToastUtil.showLoading('加载中...');
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      this.map = new AMap.Map('container');
      this.map.setZoom(this.ZOOM); //设置地图层级
      this.map.on('complete', () => {
        // 地图图块加载完成后触发
        ToastUtil.hide();
        this.updateLocation();
        console.log('on map load complete');
      });
      this.addMapListeners();
    });
  }

  private mounted() {
  }

  private beforeDestroy() {
    this.removeMapListeners();
    if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    ToastUtil.hide();
    if (to.name == RouterDomain.SUPERVISE_EVENT && this.isSureClick) {
      if (this.curParkingPoint) {
        to.query.parkingPointNum = this.curParkingPoint.parking_point_num;
        to.query.streetName = this.curParkingPoint.street_name;
        to.query.pointLimit = String(this.curParkingPoint.point_limit);
        to.query.parkingPointArea = this.curParkingPoint.parking_point_area;
      } else if (this.address) {
        to.query.lon = String(this.address.lng);
        to.query.lat = String(this.address.lat);
        to.query.address = String(this.address.simpleName);
        to.query.regionCode = String(this.address.regionCode);
      }
    }
    next();
  }

  /**
   * 点击定位图标
   */
  public onLocateClick(): void {
    this.updateLocation();
  }

  /**
   * 点击确定
   */
  public onSureClick(): void {
    if (this.isSelectParking && !this.curParkingPoint) {
      ToastUtil.showMessage('请选择位置');
      return;
    }
    if (!this.isSelectParking && !this.address) {
      ToastUtil.showMessage('请选择位置');
      return;
    }
    if (this.isSelectParking && this.curParkingPoint && this.curParkingPoint.is_has_unhandled_events) {
      return;
    }
    this.isSureClick = true;
    this.$router.go(-1);
  }

  private updateLocation() {
    if (this.map) {
      // 高德获取定位
      const tempFirstLocation = this.firstLocation;
      this.firstLocation = false;
      AmapUtil.getLocation(tempFirstLocation ? this.map : null, (status: any, result: any) => {
        if (status == 'complete') {
          this.centerLocation = new Location(result.position.lng, result.position.lat);
          this.map.setZoom(this.ZOOM);
          this.map.setCenter([result.position.lng, result.position.lat]);
          this.addCenterMarker();
          this.requestParkingPoints();
        } else {
          ToastUtil.showMessage('定位失败');
          // const result = {position: {lng: 123.45158958, lat: 41.72701431}};
          // this.centerLocation = new Location(result.position.lng, result.position.lat);
          // this.map.setZoom(this.ZOOM);
          // this.map.setCenter([result.position.lng, result.position.lat]);
          // this.addCenterMarker();
          // this.requestParkingPoints();
        }
      });
    }
  }

  private requestParkingPoints() {
    if (!this.centerLocation) {
      return;
    }
    if (!this.isSelectParking) {
      AmapUtil.getLocationAddress([this.centerLocation.lng, this.centerLocation.lat], (result: any) => {
        this.address = new Address(
          this.centerLocation?.lng,
          this.centerLocation?.lat,
          result.regeocode.addressComponent.adcode,
          result.regeocode.addressComponent.street,
          result.regeocode.addressComponent.province,
          result.regeocode.addressComponent.city,
          result.regeocode.addressComponent.district,
          result.regeocode.addressComponent.township,
          result.regeocode.formattedAddress
        );
        this.address.simpleName = this.address.fullName.replace(this.address.province + this.address.city + this.address.district, '');
      });
      return;
    }
    const requestPromise = BicycleRequest.getParkingPoints(
      String(this.centerLocation.lng),
      String(this.centerLocation.lat),
      null,
      null,
      true,
      1)
      .then((response: AxiosResponse) => {
        if (this.requestPromise !== requestPromise) {
          return;
        }
        const tempParkingPoints = HttpResponseUtil.parseJSONArray(ParkingPoint, response.data)
        if (tempParkingPoints) {
          this.parkingPointList = tempParkingPoints;
        }
        this.drawParkingPoints();
      }).catch((error: AxiosError) => {
        if (this.requestPromise !== requestPromise) {
          return;
        }
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      });
    this.requestPromise = requestPromise;
  }

  private drawParkingPoints() {
    this.clearPointMarkers();
    this.clearPointPolygons();
    const zoom = this.map.getZoom(); //获取当前地图级别
    if (zoom < MapZoomDomain.MAP_ZOOM_18) {
      this.drawPointMarkers();
    } else {
      this.drawPointPolygons();
    }
  }

  public drawPointPolygons() {
    for (const parkingPoint of this.parkingPointList) {
      const selected = this.curParkingPoint && this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id;
      this.createPointPolygon(parkingPoint, selected);
    }
  }

  private createPointPolygon(parkingPoint: ParkingPoint, selected: any): any {
    const path = ParkingPoint.parseScopePath(parkingPoint.parking_point_scope);
    const polygon = new AMap.Polygon({
      path: path,
      strokeColor: parkingPoint.is_has_unhandled_events ? '#FF0000' : '#0DBF22',// 线条颜色
      strokeWeight: 1,
      strokeOpacity: 0.2,
      fillOpacity: selected ? 1.0 : 0.4,
      fillColor: parkingPoint.is_has_unhandled_events ? '#FF0000' : '#0DBF22',// 多边形填充颜色
      borderWeight: 1, // 线条宽度，默认为 1
      zIndex: 50,
    });
    polygon.on('click', (e: any) => {
      //连续选中同一个图标
      if (this.curParkingPoint && this.curPointPolygon &&
        this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id) {
        return;
      }
      // 先把之前选中的清除选中效果
      if (this.curParkingPoint && this.curPointPolygon) {
        this.map.remove(this.curPointPolygon);
        const index = this.polygons.indexOf(this.curPointPolygon);
        this.polygons.splice(index, 1)
        this.createPointPolygon(this.curParkingPoint, false);
      }
      // 清除选中点位未点击效果，增加选中效果
      this.curParkingPoint = parkingPoint;
      this.map.remove(polygon);
      const index = this.polygons.indexOf(polygon);
      this.polygons.splice(index, 1)
      this.createPointPolygon(parkingPoint, true);
    });
    this.map.add(polygon);
    this.polygons.push(polygon);
    if (selected) {
      this.curPointPolygon = polygon;
    }
    return polygon;
  }

  public clearPointPolygons() {
    for (const polygon of this.polygons) {
      this.map.remove(polygon);
    }
    this.polygons = [];
    this.curPointPolygon = null;
  }

  public drawPointMarkers() {
    for (const parkingPoint of this.parkingPointList) {
      const selected = this.curParkingPoint && this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id;
      this.createPointMarker(parkingPoint, selected);
    }
  }

  private createPointMarker(parkingPoint: ParkingPoint, selected: any): any {
    const path = ParkingPoint.parseScopePath(parkingPoint.parking_point_scope);
    const iconPath = parkingPoint.is_has_unhandled_events ? PARKING_DISABLE_ICON : PARKING_ICON;
    var icon = new AMap.Icon({
      size: new AMap.Size(36, 46),    // 图标尺寸
      image: iconPath,  // Icon的图像
      imageSize: new AMap.Size(selected ? 36 : 36 * 0.7, selected ? 46 : 46 * 0.7)   // 根据所设置的大小拉伸或压缩图片
    });

    // 将 Icon 实例添加到 marker 上:
    const marker = new AMap.Marker({
      position: new AMap.LngLat(path[0][0], path[0][1]),
      icon: icon
    });
    marker.on('click', (e: any) => {
      //连续选中同一个图标
      if (this.curParkingPoint && this.curPointMarker &&
        this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id) {
        return;
      }
      // 先把之前选中的清除选中效果
      if (this.curParkingPoint && this.curPointMarker) {
        this.map.remove(this.curPointMarker);
        const index = this.markerList.indexOf(this.curPointMarker);
        this.markerList.splice(index, 1)
        this.createPointMarker(this.curParkingPoint, false);
      }
      // 清除选中点位未点击效果，增加选中效果
      this.curParkingPoint = parkingPoint;
      this.map.remove(marker);
      const index = this.markerList.indexOf(marker);
      this.markerList.splice(index, 1)
      this.createPointMarker(parkingPoint, true);
    });
    this.map.add(marker);
    this.markerList.push(marker);
    if (selected) {
      this.curPointMarker = marker;
    }
    return marker;
  }


  public clearPointMarkers() {
    for (const marker of this.markerList) {
      this.map.remove(marker);
    }
    this.markerList = [];
    this.curPointMarker = null;

  }

  public addCenterMarker() {
    if (!this.isSelectParking && this.centerLocation && !this.centerMarker) {
      const marker = AmapUtil.getOffetMarker(this.centerLocation.lat, this.centerLocation.lng, CENTER_LOCATION_ICON, [-17, -33]);
      this.map.add(marker);
      this.centerMarker = marker;
    }
  }

  public mapMoveStart() {

  }

  public mapMove() {
    if (this.map && this.centerMarker) {
      const center = this.map.getCenter(); //获取当前地图中心位置
      this.centerMarker.setPosition([center.lng, center.lat]);
    }
  }

  public mapMoveEnd() {
    const center = this.map.getCenter(); //获取当前地图中心位置
    this.centerLocation = new Location(center.lng, center.lat);
    this.requestParkingPoints();
  }

  public addMapListeners() {
    if (this.map) {
      this.map.on('movestart', this.mapMoveStart);
      this.map.on('mapmove', this.mapMove);
      this.map.on('moveend', this.mapMoveEnd);
    }
  }

  public removeMapListeners() {
    if (this.map) {
      this.map.off('movestart', this.mapMoveStart);
      this.map.off('mapmove', this.mapMove);
      this.map.off('moveend', this.mapMoveEnd);
    }
  }

  // 在渲染该组件的对应路由被 confirm 前调用
// 不！能！获取组件实例 `this`
// 因为当守卫执行前，组件实例还没被创建
  beforeRouteEnter(to: Route, from: Route, next: () => void): void {
    const eventType = Number(to.query.eventType);
    if (eventType === 5) {
      document.title = '选择停放点';
    } else {
      document.title = '选择位置';
    }
    next();
  }
}
