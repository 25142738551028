import { render, staticRenderFns } from "./supervise-event.vue?vue&type=template&id=6fb2e561&scoped=true&"
import script from "./supervise-event.ts?vue&type=script&lang=ts&"
export * from "./supervise-event.ts?vue&type=script&lang=ts&"
import style0 from "./supervise-event.less?vue&type=style&index=0&id=6fb2e561&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb2e561",
  null
  
)

export default component.exports