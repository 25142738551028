import {AxiosPromise} from "axios";
import axiosagent from "@/http/axios_agent";

const FormData = require('form-data');

export class UserRequest {

  /**
   * 登录接口
   * @param username
   * @param passpord
   */
  public static login(username: string, passpord: string | null): AxiosPromise {
    const formData = new FormData();
    if (username) {
      formData.append('username', username);
    }
    if (passpord) {
      formData.append('password', passpord);
    }
    return axiosagent.shared_bicycle_service.post('app/login', formData);
  }

  /**
   * 获取用户信息
   */
  public static getUserInfo(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('app/user');
  }

  /**
   * 修改密码
   * @param {string} oldPwd
   * @param {string} newPwd
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static modifyPassword(oldPwd: string, newPwd: string) {
    return axiosagent.shared_bicycle_service.patch('app/user/password', {
      old_password:oldPwd,
      new_password:newPwd
    });
  }

  /**
   * 注销
   */
  public static logout(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('app/logout');
  }
}
