import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import SimpleDemo from "@/pages/supervision/simpledemo/SimpleDemo.vue";
import Login from "@/pages/supervision/login/Login.vue";
import Main from "@/pages/supervision/main/Main.vue";
import EventList from "@/pages/supervision/eventlist/EventList.vue";
import SuperviseEvent from "@/pages/supervision/supervise/create/supervise-event.vue";
import AMapDemo from "@/pages/supervision/amapdemo/AMapDemo.vue";
import EventProcess from "@/pages/supervision/eventprocess/EventProcess.vue";
import authenticator from "@/pages/supervision/authenticator";
import BicycleInfo from "@/pages/supervision/bicycle/bicycle-info.vue";
import User from "@/pages/supervision/user/user.vue";
import ModifyPwd from "@/pages/supervision/user/pwd/modify-pwd.vue";
import SuperviseEventDetail from "@/pages/supervision/supervise/detail/supervise-event-detail.vue";
import LocationSelector from "@/pages/supervision/locationselector/LocationSelector.vue";
import {RouterDomain} from "@/domain/router-domain";
import ScanRecordBicycle from "@/pages/supervision/record/scan-record-bicycle.vue";
import AdviceLocationSelector from "@/pages/supervision/advice/map/advice-location-selector.vue";
import PublicAdvice from "@/pages/supervision/advice/public-advice.vue";
import Statistics from "@/pages/supervision/statistics/Statistics.vue";
import EventSubmits from "@/pages/supervision/event-submits/EventSubmits.vue";
import RecommendList from "@/pages/supervision/recommend-list/RecommendList.vue";
import RecommendDetail from "@/pages/supervision/recommend-detail/RecommendDetail.vue";
import AssessmentRanking from "@/pages/supervision/assessment-ranking/assessment-ranking.vue";
import CompanyAssessmentRanking from "@/pages/supervision/company-assessment-ranking/company-assessment-ranking.vue";
import CompanyCoordinate from "@/pages/supervision/company-coordinate/company-coordinate.vue";
import RegionRanking from "@/pages/supervision/region-ranking/region-ranking.vue";
import RealTimeMap from "@/pages/supervision/real-time-map/real-time-map.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/simpleDemo',
    name: 'SimpleDemo',
    component: SimpleDemo
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/main',
    name: RouterDomain.MAIN,
    component: Main,
    meta: {
      title: '单车监管'
    },
    beforeEnter: (to, from, next) => {
      authenticator.auth(() => {
        next();
      }, () => {
        next({name: 'Login', replace: true});
      });
    }
  },
  {
    path: '/event-list',
    name: 'EventList',
    component: EventList,
    meta: {
      title: '事件处理记录'
    }
  },
  {
    path: '/event-submits',
    name: 'EventCommits',
    component: EventSubmits,
    meta: {
      title: '事件提交记录'
    }
  },
  {
    path: '/recommend-list',
    name: 'RecommendList',
    component: RecommendList,
    meta: {
      title: '建议记录'
    }
  },
  {
    path: '/recommend-detail',
    name: 'RecommendDetail',
    component: RecommendDetail,
    meta: {
      title: '建议详情'
    }
  },
  {
    path: '/event-process',
    name: RouterDomain.EVENTPROCESS,
    component: EventProcess,
    meta: {
      title: '事件处理'
    }
  },
  {
    path: '/supervise-event',
    name: RouterDomain.SUPERVISE_EVENT,
    component: SuperviseEvent, meta: {
      title: '监管事件'
    }
  },
  {
    path: '/amap-demo',
    name: 'AMapDemo',
    component: AMapDemo, meta: {
      title: '高德demo'
    }
  },
  {
    path: '/bicycle-info',
    name: RouterDomain.BICYCLE_INFO,
    component: BicycleInfo, meta: {
      title: '获取单车信息'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User, meta: {
      title: '个人中心'
    }
  },
  {
    path: '/modify-pwd',
    name: 'modifyPwd',
    component: ModifyPwd, meta: {
      title: '修改密码'
    }
  },
  {
    path: '/supervise-event-detail',
    name: 'SuperviseEventDetail',
    component: SuperviseEventDetail, meta: {
      title: '事件详情'
    }
  },
  {
    path: '/location-selector',
    name: 'LocationSelector',
    component: LocationSelector,
    meta: {
      title: '选择位置'
    }
  },
  {
    path: '/advice-location-selector',
    name: 'AdviceLocationSelector',
    component: AdviceLocationSelector,
    meta: {
      title: '选择位置'
    }
  },
  {
    path: '/scan-record-bicycle',
    name: RouterDomain.SCAN_RECORD_BICYCLE,
    component: ScanRecordBicycle,
    meta: {
      title: '扫码验车'
    }
  },
  {
    path: '/public-advice',
    name: RouterDomain.PUBLIC_ADVICE,
    component: PublicAdvice,
    meta: {
      title: '建议'
    }
  },
  {
    path: '/statistics',
    name: RouterDomain.statistics,
    component: Statistics,
    meta: {
      title: '实时信息'
    }
  },
  {
    path: '/assessment-ranking',
    name: RouterDomain.ASSESSMENT_RANKING,
    component: AssessmentRanking,
    meta: {
      title: '考核排名'
    }
  },
  {
    path: '/company-assessment-ranking',
    name: RouterDomain.COMPANY_ASSESSMENT_RANKING,
    component: CompanyAssessmentRanking,
    meta: {
      title: '企业考核排名'
    }
  },
  {
    path: '/region-ranking',
    name: RouterDomain.REGION_RANKING,
    component: RegionRanking,
    meta: {
      title: '区考核排名'
    }
  },
  {
    path: '/company-coordinate',
    name: RouterDomain.COMPANY_COORDINATE,
    component: CompanyCoordinate,
    meta: {
      title: '企业配合度录入'
    }
  },
  {
    path: '/real-time-map',
    name: RouterDomain.REAL_TIME_MAP,
    component: RealTimeMap,
    meta: {
      title: '实时地图'
    }
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

export default router
