import {Vue, Component} from "vue-property-decorator";
import {Cell} from "vant";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import {RankingRequest} from "@/request/ranking-request";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";

@Component({
  components: {
    [Cell.name]: Cell
  }
})
export default class AssessmentRanking extends Vue {

  public showCompanyCoordinate = false;

  mounted() {
    LocalStorageAgent.getUserInfo()!.user_types.find((userType): any => {
      // 4,统筹监管 5,区监管 6,街道监管
      if (userType === 5) {
        RankingRequest.getRegionCooperateDegreeSetting().then((response) => {
          this.showCompanyCoordinate = response.data.ratio !== 0;
        }).catch((error) => {
          let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
          ToastUtil.showMessage(resError);
        });
      } else if (userType === 6) {
        RankingRequest.getStreetCooperateDegreeSetting().then((response) => {
          this.showCompanyCoordinate = response.data.ratio !== 0;
        }).catch((error) => {
          let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
          ToastUtil.showMessage(resError);
        });
      }
    });
  }

}
