export default class RegionInfoDomain {
  public static readonly ALL_CODE = '';
  public static readonly ALL_NAME = '全部区';
  public static readonly REGIONS: Array<Object> = [
    {
      name: RegionInfoDomain.ALL_NAME,
      code: RegionInfoDomain.ALL_CODE
    },
    {
      name: '萨尔图区',
      code: '230602'
    },
    {
      name: '龙凤区',
      code: '230603'
    },
    {
      name: '让胡路区',
      code: '230604'
    },
    {
      name: '红岗区',
      code: '230605'
    }, {
      name: '大同区',
      code: '230606'
    }
  ];
}





