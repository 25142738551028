export class CompanyDispatchInfo {
  public company_dispatch_id!: string;
  public dispatch_type!: number;
  public company_type!: number;
  public dispatch_point!: string;
  public car_id!: string;
  public telephone!: string;
  public realname!: string;

  /**
   * 1:正常 2:运维不在岗
   */
  public detected_work_status!: number;

}
