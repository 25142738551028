import {Component, Vue} from "vue-property-decorator";
import {Button, Tabs, Tab} from "vant";
import EventsView from "@/pages/supervision/eventlist/eventsview/EventsView.vue";

@Component({
  components: {[Button.name]: Button, [Tabs.name]: Tabs, [Tab.name]: Tab, 'events-view': EventsView}
})

export default class Login extends Vue {
  public tabIndex = 0;
  public tabs = [{text: '待处理'}, {text: '已处理'}];
  public fromThirdPart = 'false';

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
  }

  private mounted() {
    console.log('mounted');
    this.fromThirdPart = String(this.$route.query.fromThirdPart);
  }

  public onRefreshClick() {
    if (this.tabIndex === 0) {
      (this.$refs.unprocessedEventsView as any).refresh();
    } else {
      (this.$refs.processedEventsView as any).refresh();
    }
  }

  public onTabClick(index: number) {
    this.tabIndex = index;
  }
}
