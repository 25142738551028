export class CityComAssessStatistics {

  public city_com_assess_statistics_id!:string;
  public company_type!:number;
  //总分
  public total_score!:number;
  //事件分数
  public event_score!:number;
  //淤积分数
  public overrun_score!:number;
  //入栏分数
  public entry_score!:number;
  //抽查评价得分
  public evaluate_score!:number;
  //接入平台质量得分
  public quality_score!:number;
  //排名
  public rank!:number;
  public created_time!:number;
}
