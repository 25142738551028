import {Component, Vue} from "vue-property-decorator";
import {DatetimePicker, DropdownItem, DropdownMenu, Field, Icon, List} from "vant";
import TimeUtil from "@/util/time-util";
import {RegionAdministrationAssessStatistics} from "@/biz/region-administration-assess-statistics";
import {RankingRequest} from "@/request/ranking-request";
import {RankingDomain} from "@/domain/ranking-domain";
import {ToastUtil} from "@/util/toast-util";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {RegionCodeUtil} from "@/util/region-code-util";

@Component({
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [List.name]: List
  }
})
export default class RegionRanking extends Vue {

  public showCalendar = false;
  public pickerType: string = 'date';
  public currentDate = new Date(2021, 0, 17);
  public titleName: string = '选择年月日';
  public minDateTime = new Date(2021, 6, 1);
  public maxDateTime = new Date(2025, 10, 1);
  public menus = [
    {text: '按日', value: 0},
    {text: '按周', value: 1},
    {text: '按月', value: 2},
  ];
  public menuValue = 0;

  public selectTime = '';

  public yestodayTime = new Date().getTime() - 24 * 60 * 60 * 1000;

  private show: boolean = false;

  public rankingList: Array<RegionAdministrationAssessStatistics> = [];

  public requestTime!: number;

  private mounted() {
    this.selectDayTime();
    this.setCurrentDate(this.maxDateTime);
    this.requestTime = parseInt(String(TimeUtil.getDayZeroTime(this.currentDate)));
    this.requestData();
  }

  public setCurrentDate(currentDate: Date) {
    this.currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  private requestData() {
    ToastUtil.showLoading('加载中');
    if (this.menuValue == RankingDomain.SELECT_BY_DAY) {
      RankingRequest.getRegionStatisticsByDay(this.requestTime, undefined).then((response) => {
        this.rankingList = HttpResponseUtil.parseJSONArray(RegionAdministrationAssessStatistics, response.data);
        ToastUtil.hide();
      }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        if (error.response?.status !== 404) {
          ToastUtil.showMessage(resError);
        }
      });
    } else if (this.menuValue == RankingDomain.SELECT_BY_WEEK) {
      RankingRequest.getRegionStatisticsByWeek(this.requestTime, undefined).then((response) => {
        ToastUtil.hide();
        this.rankingList = HttpResponseUtil.parseJSONArray(RegionAdministrationAssessStatistics, response.data);
      }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        if (error.response?.status !== 404) {
          ToastUtil.showMessage(resError);
        }
      });
    } else {
      RankingRequest.getRegionStatisticsByMonth(this.requestTime, undefined).then((response) => {
        ToastUtil.hide();
        this.rankingList = HttpResponseUtil.parseJSONArray(RegionAdministrationAssessStatistics, response.data);
      }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        if (error.response?.status !== 404) {
          ToastUtil.showMessage(resError);
        }
      });
    }
  }

  public getRegionName(regionCode: string) {
    return RegionCodeUtil.getRegionName(regionCode);
  }

  public onTimeConfirm(date: Date) {
    if (this.menuValue == RankingDomain.SELECT_BY_DAY) {
      this.selectTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.requestTime = parseInt(String(date.getTime() / 1000));
    } else if (this.menuValue == RankingDomain.SELECT_BY_WEEK) {
      //算当天
      const selectWeekDate = TimeUtil.getFirstDayOfWeek(date);
      const selectWeekLastDate = TimeUtil.getLastDayOfWeek(date);
      this.selectTime = TimeUtil.getDateYYYY_MM_DD(selectWeekDate.getTime())
        + '-' + TimeUtil.getDateYYYY_MM_DD(selectWeekLastDate.getTime());
      this.requestTime = parseInt(String(selectWeekDate.getTime() / 1000));
    } else {
      this.selectTime = TimeUtil.getLastMonth(date);
      this.requestTime = parseInt(String(TimeUtil.getMonthFirstDayTime(date)));
    }
    this.showCalendar = false;
    console.log(date);
    this.requestData();
  }

  public onTimeCancel() {
    this.showCalendar = false;
  }

  public onMenuChange(value: number) {
    this.menuValue = value;
    if (this.menuValue == RankingDomain.SELECT_BY_DAY) {
      this.pickerType = 'date';
      this.titleName = '选择年月日';
      this.selectDayTime();
      this.setCurrentDate(this.maxDateTime);
      this.minDateTime = new Date(2021, 6, 1);
      this.requestTime = parseInt(String(this.maxDateTime.getTime() / 1000));
    } else if (this.menuValue == RankingDomain.SELECT_BY_WEEK) {
      this.pickerType = 'date';
      this.titleName = '选择年月日';
      this.maxDateTime = new Date(this.yestodayTime);
      const lastWeek = this.yestodayTime - 6 * 24 * 3600 * 1000;
      const lastWeekDate = new Date(lastWeek);
      const selectWeekDate = TimeUtil.getFirstDayOfWeek(lastWeekDate);
      this.setCurrentDate(selectWeekDate);
      this.minDateTime = new Date(2021, 6, 1);
      const selectWeekLastDate = TimeUtil.getLastDayOfWeek(lastWeekDate);
      this.selectTime = TimeUtil.getDateYYYY_MM_DD(selectWeekDate.getTime())
        + '-' + TimeUtil.getDateYYYY_MM_DD(selectWeekLastDate.getTime());
      this.requestTime = parseInt(String(selectWeekDate.getTime() / 1000));
    } else {
      this.pickerType = 'year-month';
      this.titleName = '选择年月';
      this.maxDateTime = TimeUtil.getMonthFirstDay();
      this.minDateTime = new Date(2021, 5, 1);
      this.setCurrentDate(this.maxDateTime);
      this.selectTime = TimeUtil.getLastMonth(this.maxDateTime);
      this.requestTime = parseInt(String(this.maxDateTime.getTime() / 1000));
    }
    this.requestData();
  }

  public showCalendarClick() {
    this.showCalendar = true;
  }

  private selectDayTime() {
    this.maxDateTime = new Date(this.yestodayTime);
    this.selectTime = this.maxDateTime.getFullYear() + "-" + (this.maxDateTime.getMonth() + 1) + "-" + this.maxDateTime.getDate();
  }

  public getRankImg(rank: number): string {
    if (rank === 1) {
      return require('../../../assets/images/icon_ranking_first@1x.png');
    } else if (rank === 2) {
      return require('../../../assets/images/icon_ranking_second@1x.png');
    } else if (rank === 3) {
      return require('../../../assets/images/icon_ranking_third@1x.png');
    }
    return '';
  }
}
