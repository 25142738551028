import {Component, Vue} from "vue-property-decorator";
import {Cascader, Field, Popup, Loading} from 'vant';
import {StatisticsRequest} from "@/request/statistics-request";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import TimeUtil from "@/util/time-util";
import RegionInfoDomain from "@/domain/region-info-domain";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";

@Component({
  components:
    {
      [Cascader.name]: Cascader,
      [Field.name]: Field,
      [Popup.name]: Popup,
      [Loading.name]: Loading
    }, filters: {
    thousandsFormat(value: string): string {
      if (value === '') {
        return '--';
      } else if (value === '0') {
        return '0';
      } else {
        return Number(value).toLocaleString();
      }
    }, toPercent(point: number) {
      let str = Number(point * 100).toFixed(1);
      str += "%";
      return str;
    }
  }
})
export default class Statistics extends Vue {

  private cascaderValue: string = '';

  private show: boolean = false;
  //选择输入框值
  private fieldValue: string;
  //区编码
  private regionCode: string;

  //全部街道显示今日订单数、正在骑行单车项
  private showStreetData = true;

  private fieldNames: Object = {
    text: 'name',
    value: 'code'
  }
  // 选项列表，children 代表子选项，支持多级嵌套
  private options: Array<Object> = RegionInfoDomain.REGIONS;

  //停放单车数
  private parked_bicycle_count: string = '';
  //青桔停放单车数
  private qj_parked_bicycle_count: string = '';
  //美团停放单车数
  private mt_parked_bicycle_count: string = '';
  //哈罗停放单车数
  private hl_parked_bicycle_count: string = '';

  //停放点数
  private parking_point_count: string = "";
  //正常停放点数
  private normal_parking_point_count: string = "";
  //超限停放点数
  private abnormal_parking_point_count: string = "";
  //实时入栏率
  private bicycle_in_parking_point_rate: string = "";
  //青桔实时入栏率
  private qj_bicycle_in_parking_point_rate: string = "";
  //美团时入栏率
  private mt_bicycle_in_parking_point_rate: string = "";
  //哈罗时入栏率
  private hl_bicycle_in_parking_point_rate: string = "";
  //在线运维人员
  private operation_count_personnel: string = "";
  private qj_operation_count_personnel: string = "";
  private mt_operation_count_personnel: string = "";
  private hl_operation_count_personnel: string = "";
  //在线运维车辆
  private operation_count_vehicle: string = "";
  private qj_operation_count_vehicle: string = "";
  private mt_operation_count_vehicle: string = "";
  private hl_operation_count_vehicle: string = "";
  //正在骑行单车
  private bicycling_count: string = "";
  private qj_bicycling_count: string = "";
  private mt_bicycling_count: string = "";
  private hl_bicycling_count: string = "";
  //今日订单数
  private bicycle_order_count: string = "";
  private qj_bicycle_order_count: string = "";
  private mt_bicycle_order_count: string = "";
  private hl_bicycle_order_count: string = "";
  private todayStartEndTimestamp: string;

  constructor() {
    super();
    this.regionCode = '';
    this.fieldValue = RegionInfoDomain.ALL_NAME;
    this.todayStartEndTimestamp = Math.floor(TimeUtil.getTodayStartEndTimestamp()[0] / 1000) + ',' + Math.floor(TimeUtil.getTodayStartEndTimestamp()[1] / 1000);
  }

  public created(): void {
    this.refreshData();
  }

  private refreshData() {
    //获取停放单车数
    StatisticsRequest.getParkedBicycleCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getParkedBicycleCountSuccess).catch(this.getParkedBicycleCountFalied);
    //获取停放点数
    StatisticsRequest.getParkingPointCount(this.regionCode, null).then(this.getParkingPointCountSuccess).catch(this.getParkingPointCountFalied);
    //获取入栏率
    StatisticsRequest.getBicycleInParkingPointRate(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicycleInParkingPointRateSuccess).catch(this.getBicycleInParkingPointRateFalied);
    //获取运维人员数量
    StatisticsRequest.getOperationCount('1', this.todayStartEndTimestamp, this.regionCode, null).then(this.getOperationCountSuccess).catch(this.getOperationCountFalied)
    //获取运维车辆数量
    StatisticsRequest.getOperationCount('2', this.todayStartEndTimestamp, this.regionCode, null).then(this.getOperationCountSuccess2).catch(this.getOperationCountFalied2)
    //获取骑行中单车数量
    StatisticsRequest.getBicyclingCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicyclingCountSuccess).catch(this.getBicyclingCountFalied);
    //获取订单数
    StatisticsRequest.getBicycleOrderCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicycleOrderCountSuccess).catch(this.getBicycleOrderCountFalied);
  }

  public getBicycleOrderCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.bicycle_order_count = response.data.total_order_count;
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
            this.qj_bicycle_order_count = item.order_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
            this.hl_bicycle_order_count = item.order_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
            this.mt_bicycle_order_count = item.order_count;
          }
        });
      }
    }
  }

  public getBicycleOrderCountFalied(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public getBicyclingCountSuccess(response: AxiosResponse): void {
    // console.log(response);
    if (response.data) {
      this.bicycling_count = response.data.total_bicycling_count;
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
            this.qj_bicycling_count = item.bicycling_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
            this.hl_bicycling_count = item.bicycling_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
            this.mt_bicycling_count = item.bicycling_count;
          }
        });
      }
    }
  }

  public getBicyclingCountFalied(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public getOperationCountSuccess2(response: AxiosResponse): void {
    if (response.data) {
      this.operation_count_personnel = response.data.total_operation_count;
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
            this.qj_operation_count_personnel = item.operation_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
            this.hl_operation_count_personnel = item.operation_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
            this.mt_operation_count_personnel = item.operation_count;
          }
        });
      }
    }
  }

  public getOperationCountFalied2(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public getOperationCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.operation_count_vehicle = response.data.total_operation_count;
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
            this.qj_operation_count_vehicle = item.operation_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
            this.hl_operation_count_vehicle = item.operation_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
            this.mt_operation_count_vehicle = item.operation_count;
          }
        });
      }
    }
  }

  public getOperationCountFalied(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public getBicycleInParkingPointRateSuccess(response: AxiosResponse): void {
    // console.log(response);
    if (response.data) {
      //实时入栏率
      this.bicycle_in_parking_point_rate = response.data.total_parking_point_rate;
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
            this.qj_bicycle_in_parking_point_rate = item.parking_point_rate;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
            this.hl_bicycle_in_parking_point_rate = item.parking_point_rate;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
            this.mt_bicycle_in_parking_point_rate = item.parking_point_rate;
          }
        });
      }
    }
  }

  public getBicycleInParkingPointRateFalied(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }


  public getParkingPointCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      response.data.forEach((item: any) => {//1:正常,2:超限
        if (item.parking_point_use_status === 1) {
          this.normal_parking_point_count = String(item.parking_point_count);
        } else if (item.parking_point_use_status === 2) {
          this.abnormal_parking_point_count = String(item.parking_point_count);
        }
      });
      this.parking_point_count = String(Number(this.normal_parking_point_count) + Number(this.abnormal_parking_point_count));
    }
  }

  public getParkingPointCountFalied(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public getParkedBicycleCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.parked_bicycle_count = response.data.total_bicycle_count;
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
            this.qj_parked_bicycle_count = item.bicycle_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
            this.hl_parked_bicycle_count = item.bicycle_count;
          } else if (item.company_type == BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
            this.mt_parked_bicycle_count = item.bicycle_count;
          }
        });
      }
    }
  }

  public getParkedBicycleCountFalied(error: AxiosError): void {
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public reset(): void {
    this.parked_bicycle_count = '';
    this.qj_parked_bicycle_count = '';
    this.mt_parked_bicycle_count = '';
    this.hl_parked_bicycle_count = '';

    this.parking_point_count = '';
    this.normal_parking_point_count = '';
    this.abnormal_parking_point_count = '';

    this.bicycle_in_parking_point_rate = '';
    this.qj_bicycle_in_parking_point_rate = '';
    this.mt_bicycle_in_parking_point_rate = '';
    this.hl_bicycle_in_parking_point_rate = '';

    this.operation_count_personnel = '';
    this.qj_operation_count_personnel = '';
    this.mt_operation_count_personnel = '';
    this.hl_operation_count_personnel = '';

    this.operation_count_vehicle = '';
    this.qj_operation_count_vehicle = '';
    this.mt_operation_count_vehicle = '';
    this.hl_operation_count_vehicle = '';

    this.bicycling_count = '';
    this.qj_bicycling_count = '';
    this.mt_bicycling_count = '';
    this.hl_bicycling_count = '';

    this.bicycle_order_count = '';
    this.qj_bicycle_order_count = '';
    this.mt_bicycle_order_count = '';
    this.hl_bicycle_order_count = '';
  }

  public onFinish(val: any): void {
    this.show = false;
    this.regionCode = val.selectedOptions[0].code;
    if (this.regionCode == RegionInfoDomain.ALL_CODE) {
      this.regionCode = '';
      this.fieldValue = RegionInfoDomain.ALL_NAME;
      this.showStreetData = true;
    } else {
      this.fieldValue = val.selectedOptions[0].name;
      this.showStreetData = false;
    }

    this.reset();
    (this.$refs.statisticsDiv as any).scrollTo(0, 0);
    this.refreshData();
  }
}
