import {Vue, Component} from "vue-property-decorator";
import DispatchTypeDomain from "../../../../domain/dispatch-type-domain";
import {ToastUtil} from "../../../../util/toast-util";
import {AxiosError, AxiosResponse} from "axios";
import SuperviseEventRequest from "../../../../request/supervise-event-request";
import HttpResErrorPaser from "../../../../http/error/HttpResErrorPaser";
import {LocalStorageAgent} from "../../../../storage/local-storage-agent";
import {AmapUtil} from "@/util/amap-util";
import {EntityErrorPaser} from "@/http/error/HttpResErrorPaser";
import Error from "@/http/error/Error";
import {Field} from "vant";
import {Route} from "vue-router";
import store from "@/pages/supervision/store/store";
import {RouterDomain} from "@/domain/router-domain";
import {ComponentDomain} from "@/domain/component-domain";
import {DialogUtil} from "@/util/dialog-util";
import ValueUtil from "@/util/value-util";

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

@Component({
  components: {
    [Field.name]: Field
  }
})
export default class DialogBicycleCount extends Vue {

  get totalCount(): number {
    return Number(this.qjCount) + Number(this.hbCount) + Number(this.mbCount);
  }

  public qjCount: number = 0;
  public mbCount: number = 0;
  public hbCount: number = 0;
  private _totalCount: number = 0;

  public titleTip = '未备案单车总计';

  public showCountDialog: boolean = false;
  private meituanBicycles: string[] = [];
  private qingjuBicycles: string[] = [];
  private haluoBicycles: string[] = [];

  private addressName: string = '';
  private regionCode: string = '';
  private lon: number = 0;
  private lat: number = 0;

  private remarkStr: string = '';


  private dispatchType: number = DispatchTypeDomain.DISPATCH_TYPE_VIOLATION;

  public remarkVisible = true;

  public bicyclePhotos: Array<string> = [];
  public parkingPointNum: any = '';

  public subEventTypes: Array<string> = [];

  private created(): void {
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      // 高德获取定位
      AmapUtil.getLocation(null, (status: any, result: any) => {
        if (status == 'complete') {
          this.lon = result.position.lng;
          this.lat = result.position.lat;
          this.regionCode = result.addressComponent.adcode;
          AmapUtil.getLocationAddress([this.lon, this.lat], (result: any) => {
            this.addressName = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province
              + result.regeocode.addressComponent.city, '');
          });
        } else {
          ToastUtil.showMessage('高德定位失败 ' + JSON.stringify(result))
        }
      });
    });
  }

  public showDialog() {
    this.showCountDialog = true;
    this.bicyclePhotos = LocalStorageAgent.getPhotoPath();
    this.parkingPointNum = this.$route.query.parkingPointNum;
    this.subEventTypes = LocalStorageAgent.getSubEventTypes();
  }

  public setData(dispatchType: number, meituanBicycles: string[], qingjuBicycles: string[],
                 haluoBicycles: string[]) {
    this.dispatchType = dispatchType;
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_VIOLATION) {
      this.remarkVisible = false;
      this.titleTip = '未备案单车总计';
    } else {
      this.remarkVisible = true;
      this.titleTip = '单车总计';
    }
    this.haluoBicycles = haluoBicycles;
    this.qingjuBicycles = qingjuBicycles;
    this.meituanBicycles = meituanBicycles;
    this.qjCount = qingjuBicycles.length;
    this.mbCount = meituanBicycles.length;
    this.hbCount = haluoBicycles.length;
    this.setTotalCount();
  }

  private setTotalCount() {
    this._totalCount = this.qjCount + this.hbCount + this.mbCount;
  }

  public onConfirmClick() {
    if (this.qjCount > 10000 || this.mbCount > 10000 || this.hbCount > 10000) {
      ToastUtil.showMessage("车辆数不能超过10000");
      return;
    }
    if (this.totalCount == 0) {
      ToastUtil.showMessage("未获得车辆信息，无法创建事件");
      return;
    }
    if (this.addressName && this.regionCode) {
      this.commit();
    } else {
      ToastUtil.showMessage('请选择位置');
    }
  }

  public onCancelClick() {
    this.showCountDialog = false;
  }

  private commit() {
    ToastUtil.showLoading('正在创建事件');
    SuperviseEventRequest.createEvent('', '', this.dispatchType,
      this.bicyclePhotos, this.meituanBicycles, this.qingjuBicycles, this.haluoBicycles, this.addressName, this.regionCode,
      this.lon, this.lat, this.mbCount, this.qjCount, this.hbCount, this.remarkStr, this.subEventTypes, this.parkingPointNum, null)
      .then((response: AxiosResponse) => {
          ToastUtil.hide();
          ToastUtil.showMessage('创建成功');
          this.clearStore();
          if (this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_VIOLATION) {
            this.$router.push({
              path: '/event-process',
              query: {
                regulator_event_id: response.data.regulator_event_id
              }
            });
          } else {
            this.$router.go(-2);
          }
        }
      ).catch((error: AxiosError) => {
        ToastUtil.hide();
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
          public parseUnprocessableEntity(error: Error): string {
            if (error != null) {
              if (error.resource === 'regulator_event' && error.code === 'repeat_submit') {
                DialogUtil.showTitleAlertDialog('提示', '已有市民在此地点反馈问题，不能重复反馈。');
                return '';
              }
            }
            return '创建失败';
          }
        });
        if (!ValueUtil.isStrEmpty(resError)) {
          ToastUtil.showMessage(resError);
        }
      }
    )
  }

  private clearStore() {
    LocalStorageAgent.setSubEventTypes(null);
    LocalStorageAgent.setPhotoPath(null);
  }

// 在渲染该组件的对应路由被 confirm 前调用
  // 不！能！获取组件实例 `this`
  // 因为当守卫执行前，组件实例还没被创建
  beforeRouteEnter(to: Route, from: Route, next: () => void): void {
    next();
  }

  // 在当前路由改变，但是该组件被复用时调用
  // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
  // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
  // 可以访问组件实例 `this`
  beforeRouteUpdate(to: Route, from: Route, next: () => void): void {
    next();
  }

  // 导航离开该组件的对应路由时调用
  // 可以访问组件实例 `this`
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (to.name == RouterDomain.MAIN || to.name == RouterDomain.EVENTPROCESS) {
      store.dispatch("removeInclude", ComponentDomain.SUPERVISE_EVENT);
      store.dispatch("removeInclude", ComponentDomain.BICYCLE_INFO);
    }
    next();
  }
}
