import {Vue, Component} from "vue-property-decorator";
import {Col, Grid, GridItem, ImagePreview, Row} from "vant";
import SuperviseEventRequest from "../../../../request/supervise-event-request";
import HttpResponseUtil from "../../../../http/HttpResponseUtil";
import {AxiosError, AxiosResponse} from "axios";
import {ToastUtil} from "../../../../util/toast-util";
import HttpResErrorPaser from "../../../../http/error/HttpResErrorPaser";
import RegulatorEventDetail from "../../../../biz/regulator-event-detail";
import DispatchTypeDomain from "../../../../domain/dispatch-type-domain";
import ValueUtil from "../../../../util/value-util";
import TimeUtil from "../../../../util/time-util";
import {BicycleUtil} from "../../../../util/bicycle-util";
import DealResult from "../../../../biz/deal-result";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";

@Component({
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Col.name]: Col,
    [Row.name]: Row,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  filters: {
    bicycleCount(value: any) {
      if (value === null || value === 'undefined') {
        return '';
      }
      return '：' + value + '辆';
    }
  }
})
export default class SuperviseEventDetail extends Vue {

  public fromThirdPartEventDetail = false;

  public subTypeVisible = false;
  public bottomVisible = false;
  public bicycleCountVisible = false;
  public showMbCount = true;
  public showHbCount = true;
  public showQbCount = true;
  public remarkVisible = false;
  public savePositionVisible = false;
  public operationVisible = false;
  public companysVisible = false;
  public qjStatusVisible = false;
  public hbStatusVisible = false;
  public mbStatusVisible = false;
  public thirdStatusVisible = false;
  public hasDeal = true;

  public eventStatus: string = '';
  public eventTypeStr = '';
  public subEventType = '';
  private photoImages: string[] = [];

  public qjCount: number = 0;
  public mbCount: number = 0;
  public hbCount: number = 0;

  public qjCountTip: string = '青桔';
  public hbCountTip: string = '哈啰';
  public mbCountTip: string = '美团';

  public savePosition = '';
  public remark = '';
  //提交人
  public retainName = '';
  public retainTime = '';
  public retainAddress = '';
  public eventTime!: number;

  //运维信息
  public companyName = '';
  public bicycleType = '';
  public contactName = '';
  public phoneNum = '';

  //企业私设停放点企业
  public companyNames = '';
  //青桔处理状态
  public qjRetainName = '';
  public qjRetainTime = '';
  public qjRetainAddress = '';
  private qingjuPhotoImages: string[] = [];
  public qjRemark = '';
  //哈啰处理状态
  public hbRetainName = '';
  public hbRetainTime = '';
  public hbRetainAddress = '';
  private haluoPhotoImages: string[] = [];
  public hbRemark = '';
  //美团处理状态
  public mbRetainName = '';
  public mbRetainTime = '';
  public mbRetainAddress = '';
  private meituanPhotoImages: string[] = [];
  public mbRemark = '';
  //三方
  public thirdRetainName = '';
  public thirdRetainTime = '';
  private thirdPhotoImages: string[] = [];
  public thirdRemark = '';
  public thirdRetainPosition = '';

  public regulatorEventId: any = null;

  public dispatchType!: number;

  private mounted() {
    this.regulatorEventId = this.$route.query.regulatorEventId;
    if (this.$route.query.fromThirdPartEventDetail) {
      this.fromThirdPartEventDetail = Boolean(this.$route.query.fromThirdPartEventDetail);
    }
    this.getSuperviseEventDetail();
  }

  private getSuperviseEventDetail() {
    SuperviseEventRequest.getRegulatorEventDetail(this.regulatorEventId)
      .then((response: AxiosResponse) => {
        let regulatorEventDetail = HttpResponseUtil.parseJSONStr(RegulatorEventDetail, response.data);
        this.dealData(regulatorEventDetail);
      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }

  private dealData(regulatorEventDetail: RegulatorEventDetail) {
    if (regulatorEventDetail != null) {
      this.dispatchType = regulatorEventDetail.type;
      this.showEventTypeData(this.dispatchType);
      this.showSubEventData(regulatorEventDetail.regulator_event_tags);
      this.dealStatusData(regulatorEventDetail.deal_status, this.dispatchType);

      this.photoImages = regulatorEventDetail.pics;

      this.dealBicycleCount(this.dispatchType, regulatorEventDetail);
      this.eventTime = regulatorEventDetail.event_time;
      if (regulatorEventDetail.event_source === 1) {
        this.retainName = regulatorEventDetail.submitter;
      } else {
        this.retainName = regulatorEventDetail.submitter_name;
      }
      this.retainTime = TimeUtil.getDateYYYYMMDDHHMMSS(regulatorEventDetail.event_time * 1000);
      this.retainAddress = regulatorEventDetail.event_location;
      this.dealEventInfo(regulatorEventDetail, this.dispatchType);

      const thirdResult = regulatorEventDetail.third_party_result;
      const qingjuResult = regulatorEventDetail.qingju_result;
      const haluoResult = regulatorEventDetail.haluo_result;
      const meituanResult = regulatorEventDetail.meituan_result;
      this.dealRemarkAndPosition(this.dispatchType, thirdResult, regulatorEventDetail);
      if (this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_DISORDERLY
        || this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_DAMAGED
        || this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT
        || this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION) {
        this.dealQjResult(qingjuResult);
        this.dealHaluoResult(haluoResult);
        this.dealMeituanResult(meituanResult);
        this.dealThirdResult(thirdResult);
      }
    }
  }

  private dealRemarkAndPosition(dispatchType: number, thirdResult: DealResult, regulatorEventDetail: RegulatorEventDetail) {
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_VIOLATION) {
      if (thirdResult != undefined && thirdResult != null) {
        if (!ValueUtil.isStrEmpty(thirdResult.remark)) {
          this.remarkVisible = true;
          this.remark = thirdResult.remark;
        } else {
          this.remarkVisible = false;
        }
        if (!ValueUtil.isStrEmpty(thirdResult.regulator_storage_location_name)) {
          this.savePositionVisible = true;
          this.savePosition = thirdResult.regulator_storage_location_name;
        } else {
          this.savePositionVisible = false;
        }
      } else {
        this.savePositionVisible = false;
        this.remarkVisible = false;
      }
    } else {
      if (regulatorEventDetail != undefined && regulatorEventDetail != null
        && !ValueUtil.isStrEmpty(regulatorEventDetail.remark)) {
        this.remarkVisible = true;
        this.remark = regulatorEventDetail.remark;
      } else {
        this.remarkVisible = false;
      }
    }
  }

  private dealEventInfo(regulatorEventDetail: RegulatorEventDetail, dispatchType: number) {
    const eventInfo = regulatorEventDetail.event_info;
    if (eventInfo != null) {
      if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_OPERATION) {
        this.companyName = BicycleUtil.getBicycleCompanyTypeName(eventInfo.company_type);
        this.bicycleType = BicycleUtil.getDispatchType(eventInfo.dispatch_type);
        this.phoneNum = eventInfo.telephone;
        if (!ValueUtil.isStrEmpty(eventInfo.realname) && !ValueUtil.isStrEmpty(eventInfo.car_id)) {
          this.contactName = eventInfo.realname + '(' + eventInfo.car_id + ')';
        } else if (!ValueUtil.isStrEmpty(eventInfo.realname)) {
          this.contactName = eventInfo.realname;
        }
        if (!ValueUtil.isStrEmpty(this.contactName) || !ValueUtil.isStrEmpty(this.bicycleType)
          || !ValueUtil.isStrEmpty(this.bicycleType) || !ValueUtil.isStrEmpty(this.companyName)) {
          this.operationVisible = true;
        }
      } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION) {
        const companys = eventInfo.company_types;
        if (!ValueUtil.isArrEmpty(companys)) {
          this.companysVisible = true;
          companys.find((type: any) => {
            this.companyNames = this.companyNames + " " + BicycleUtil.getBicycleCompanyTypeName(type);
          });
        }
      }
    }
  }

  private dealBicycleCount(dispatchType: number, regulatorEventDetail: RegulatorEventDetail) {
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_OPERATION
      || dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION) {
      this.bicycleCountVisible = false;
    } else {
      this.bicycleCountVisible = true;
      if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT) {
        this.qjCountTip = '青桔超限';
        this.mbCountTip = '美团超限';
        this.hbCountTip = '哈啰超限';
      }
      if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_DISORDERLY ||
        dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT) {
        this.showHbCount = false;
        this.showQbCount = false;
        this.showMbCount = false;
        const companys = regulatorEventDetail.company_types;
        if (!ValueUtil.isArrEmpty(companys)) {
          companys.map((type: any) => {
            if (type === BicycleTypeDomain.COMPANY_DIDI_TYPE) {
              this.showQbCount = true;
            } else if (type === BicycleTypeDomain.COMPANY_HALLO_TYPE) {
              this.showHbCount = true;
            } else if (type === BicycleTypeDomain.COMPANY_MOBAI_TYPE) {
              this.showMbCount = true;
            }
          });
        }
      }
      this.qjCount = regulatorEventDetail.qingju_sum;
      this.hbCount = regulatorEventDetail.haluo_sum;
      this.mbCount = regulatorEventDetail.meituan_sum;
    }
  }

  private dealStatusData(status: number, dispatchType: number) {
    //1:未处理,2:处理中,3:已处理
    if (status == DispatchTypeDomain.SUPERVISE_WAIT_DEAL) {
      this.hasDeal = false;
      this.eventStatus = '未处理';
    } else if (status == DispatchTypeDomain.SUPERVISE_DEALING) {
      this.hasDeal = false;
      this.eventStatus = '处理中';
    } else {
      this.hasDeal = true;
      this.eventStatus = '已处理';
    }
    //去处理
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_OPERATION
      || dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION) {
      this.bottomVisible = false;
    } else {
      this.bottomVisible = status == DispatchTypeDomain.SUPERVISE_WAIT_DEAL;
    }
  }

  private showSubEventData(regulatorEventTags: string[]) {
    if (!ValueUtil.isArrEmpty(regulatorEventTags)) {
      this.subTypeVisible = true;
      regulatorEventTags.find((eventTag) => {
        this.subEventType = this.subEventType + " " + eventTag;
      });
    } else {
      this.subTypeVisible = false;
    }
  }

  private showEventTypeData(dispatchType: number) {
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_VIOLATION) {
      this.eventTypeStr = "违规投放";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_DISORDERLY) {
      this.eventTypeStr = "乱停放";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_OPERATION) {
      this.eventTypeStr = "运维违规";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_DAMAGED) {
      this.eventTypeStr = "破损/污渍";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT) {
      this.eventTypeStr = "停放超限";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION) {
      this.eventTypeStr = "企业私设停放点";
    }
  }

  private dealQjResult(qingjuResult: DealResult) {
    if (qingjuResult != undefined && qingjuResult != null) {
      this.qjStatusVisible = true;
      this.qjRetainName = qingjuResult.handler;
      this.qjRetainTime = TimeUtil.getDateYYYYMMDDHHMMSS(qingjuResult.deal_time * 1000);
      this.qjRemark = qingjuResult.remark;
      this.qjRetainAddress = qingjuResult.deal_position;
      this.qingjuPhotoImages = qingjuResult.deal_pics;
    }
  }

  private dealHaluoResult(haluoResult: DealResult) {
    if (haluoResult != undefined && haluoResult != null) {
      this.hbStatusVisible = true;
      this.hbRetainName = haluoResult.handler;
      this.hbRetainTime = TimeUtil.getDateYYYYMMDDHHMMSS(haluoResult.deal_time * 1000);
      this.hbRemark = haluoResult.remark;
      this.hbRetainAddress = haluoResult.deal_position;
      this.haluoPhotoImages = haluoResult.deal_pics;
    }
  }

  private dealMeituanResult(meituanResult: DealResult) {
    if (meituanResult != undefined && meituanResult != null) {
      this.mbStatusVisible = true;
      this.mbRetainName = meituanResult.handler;
      this.mbRetainTime = TimeUtil.getDateYYYYMMDDHHMMSS(meituanResult.deal_time * 1000);
      this.mbRemark = meituanResult.remark;
      this.mbRetainAddress = meituanResult.deal_position;
      this.meituanPhotoImages = meituanResult.deal_pics;
    }
  }

  private dealThirdResult(thirdResult: DealResult) {
    if (thirdResult != null) {
      this.thirdStatusVisible = true;
      this.thirdRetainName = thirdResult.handler;
      this.thirdRetainTime = TimeUtil.getDateYYYYMMDDHHMMSS(thirdResult.deal_time * 1000);
      this.thirdRemark = thirdResult.remark;
      this.thirdPhotoImages = thirdResult.deal_pics;
      this.thirdRetainPosition = thirdResult.regulator_storage_location_name;
    }
  }

  public onDealEventClick() {
    this.$router.push({
      path: '/event-process', query: {
        time: String(this.eventTime),
        type: String(this.dispatchType),
        regulator_event_id: this.regulatorEventId,
        fromThirdPartEventDetail: String(this.fromThirdPartEventDetail)
      }
    });
  }

  public onPhotoItemClick(photos: string[], index: number) {
    ImagePreview({
      images: photos,
      startPosition: index,
      closeable: true
    });
  }
}
