import {AxiosPromise} from "axios";
import axiosagent from "@/http/axios_agent";
import HttpRequestUtil from "@/http/HttpRequestUtil";

export class StatisticsRequest {

  /**
   * 获取停放单车数
   * @param region_code
   * @param street_code
   * @param section
   */
  public static getParkedBicycleCount(section?: string, region_code?: string, street_code?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (region_code) {
      paramMap.set('region_code', region_code);
    }
    if (street_code) {
      paramMap.set("street_code", street_code);
    }
    if (section) {
      paramMap.set("section", section);
    }
    return axiosagent.shared_bicycle_service.get('api/regulator/parked_bicycle_count?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取停放点数
   * @param region_code
   * @param street_code
   */
  public static getParkingPointCount(region_code?: string, street_code?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (region_code) {
      paramMap.set('region_code', region_code);
    }
    if (street_code) {
      paramMap.set("street_code", street_code);
    }
    return axiosagent.shared_bicycle_service.get('api/regulator/parking_point_count?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取入栏率
   * @param region_code
   * @param street_code
   * @param section
   */
  public static getBicycleInParkingPointRate(section?: string, region_code?: string, street_code?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (region_code) {
      paramMap.set('region_code', region_code);
    }
    if (street_code) {
      paramMap.set("street_code", street_code);
    }
    if (section) {
      paramMap.set("section", section);
    }
    return axiosagent.shared_bicycle_service.get('api/regulator/bicycle_in_parking_point_rate?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取运维数量
   * @param dispatch_type 类别 1:车辆 2:人员
   * @param region_code
   * @param street_code
   * @param section
   */
  public static getOperationCount(dispatch_type: string, section?: string, region_code?: string, street_code?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (region_code) {
      paramMap.set('region_code', region_code);
    }
    if (street_code) {
      paramMap.set("street_code", street_code);
    }
    if (section) {
      paramMap.set("section", section);
    }
    paramMap.set('dispatch_type', dispatch_type);
    return axiosagent.shared_bicycle_service.get('api/regulator/operation_count?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取骑行中单车数量
   * @param section
   * @param region_code
   * @param street_code
   */
  public static getBicyclingCount(section?: string, region_code?: string, street_code?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (region_code) {
      paramMap.set('region_code', region_code);
    }
    if (street_code) {
      paramMap.set("street_code", street_code);
    }
    if (section) {
      paramMap.set("section", section);
    }
    return axiosagent.shared_bicycle_service.get('api/regulator/bicycling_count?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取订单数
   * @param section
   * @param region_code
   * @param street_code
   */
  public static getBicycleOrderCount(section?: string, region_code?: string, street_code?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (region_code) {
      paramMap.set('region_code', region_code);
    }
    if (street_code) {
      paramMap.set("street_code", street_code);
    }
    if (section) {
      paramMap.set("section", section);
    }
    return axiosagent.shared_bicycle_service.get('api/regulator/bicycle_order_count?' + HttpRequestUtil.encodeParameters(paramMap));
  }
}
