import {Component, Vue} from 'vue-property-decorator'

import {Button, Uploader} from 'vant';
import {BicycleRequest} from "@/request/BicycleRequest";
import {AxiosError, AxiosResponse} from "axios";
import {ToastUtil} from "@/util/toast-util";

import {UserRequest} from "@/request/UserRequest";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import EntityErrorPaser from "@/http/error/HttpResErrorPaser";
import Error from "@/http/error/Error";
import {DialogUtil} from "@/util/dialog-util";
import {WxAgent} from "@/wx/wx-agent";
import {WxUtil} from "@/util/wx-util";
import environment from "@/environments/environment";
import ImageUtil from "@/util/image-util";
import SystemUtil from "@/util/system-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import TimeUtil from "@/util/time-util";
import WaterMarkUtil from "@/util/watermark-util";

@Component({
  components: {[Button.name]: Button,[Uploader.name]: Uploader}
})


export default class SimpleDemo extends Vue {

  constructor() {
    super()
  }

  private created(): void {
  }

  private mounted() {
    console.log('mounted');
    var VConsole = require('vconsole');
    var vConsole = new VConsole();
    //初始化配置
    WxUtil.wxConfig(!environment.production, ['scanQRCode', 'chooseImage']);
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      console.log('wx ready');
    });

    wx.error(function (res: any) {
      console.log('wx error');
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(JSON.stringify(res));
    });

  }

  /**
   * 发送一个get请求
   */
  public onGetRequestClick(): void {
    ToastUtil.showLoading('加载中...');
    BicycleRequest.getBicycleList('41.80472', '123.43435').then(this.onGetRequestSuccess).catch(this.onGetRequestFailed);
  }

  public onGetRequestSuccess(response: AxiosResponse): void {
    ToastUtil.hide();
    console.log(response);
  }

  public onGetRequestFailed(error: AxiosError): void {
    ToastUtil.hide();
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  /**
   * 网络请求错误解析
   */
  public onParseErrorClick(): void {
    ToastUtil.showLoading('加载中...');
    UserRequest.login('zhangdi002', null).then(this.onParseErrorSuccess).catch(this.onParseErrorFailed);
  }

  public onParseErrorSuccess(response: AxiosResponse): void {
    ToastUtil.hide();
    console.log(response);
  }

  public onParseErrorFailed(error: AxiosError): void {
    ToastUtil.hide();
    // 解析422
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
      public parseUnprocessableEntity(error: Error): string {
        console.log(error);
        if (error != null) {
          if ("password" == error.field && "missing_field" == error.code) {
            return "密码不能为null";
          } else if ("lat" == error.field && "missing_field" == error.code)
            return "用户名不能为null"
        }
        return "";
      }
    });
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public onPostRequestClick(): void {
    ToastUtil.showLoading('加载中...');
    UserRequest.login('zhangdi002', '000000').then(this.onPostRequestSuccess).catch(this.onPostRequestFailed);
  }

  public onPostRequestSuccess(response: AxiosResponse): void {
    ToastUtil.hide();
    ToastUtil.showMessage('登录成功');
    console.log(response);
  }

  public onPostRequestFailed(error: AxiosError): void {
    ToastUtil.hide();
    // 解析422
    let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
    ToastUtil.showMessage(resError);
    console.log(error);
  }

  public onConfirmDialogClick(): void {
    DialogUtil.showTitleAlertDialog('标题', '内容内容内容内容内容内容').then(() => {
        console.log('on confirm close');
      }
    ).catch(() => {
      console.log('on confirm catch');
    });
  }

  public onSelectDialogClick(): void {
    DialogUtil.showTitleConfirmDialog('标题', '内容内容内容内容内容内容').then(() => {
        console.log('on select close');
      }
    ).catch(() => {
      console.log('on select catch');
    });
  }

  /**
   * 扫二维码
   */
  public onScanQRCodeClick(): void {
    WxAgent.scanQRCode((res: any) => {
      console.log(JSON.stringify(res));
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }

  /**
   * 获取地理位置接口
   */
  public onGetLocationClick(): void {
    WxAgent.getLocation((res: any) => {
      console.log(JSON.stringify(res));
      const latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
      const longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
      const speed = res.speed; // 速度，以米/每秒计
      const accuracy = res.accuracy; // 位置精度
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }


  public onLoginClick(): void {
    this.$router.push({path: '/login'})

  }

  public onEventListClick(): void {
    this.$router.push({path: '/event-list'})
  }

  public onLocationSelectorClick(): void {
    this.$router.push({path: '/location-selector'})
  }

  public onChooseImageClick(): void {
    WxAgent.chooseImage((res: any) => {
      console.log(JSON.stringify(res));
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }

  /**
   * 获取本地图片接口
   */
  public getLocalImgData(): void {
    WxAgent.chooseImage((res: any) => {
      console.log(JSON.stringify(res));
      const localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
      console.log('localIds:');
      console.log(localIds);
      WxAgent.getLocalImgData(localIds[0], (res: any) => {
        let localData: string = res.localData; // localData是图片的base64数据，可以用img标签显示
        console.log(localData);
        if (!SystemUtil.isIOS()) {
          localData = 'data:image/jpeg/png;base64,' + localData; // localData是图片的base64数据
        }
        let timeStamp: number = new Date().getTime();
        let fileName = 'yourname_' + timeStamp + ".png";
        console.log(fileName);
        let file = ImageUtil.dataURLtoFile(localData, fileName);
        console.log(file);
        ToastUtil.showLoading('上传图片中...');
        BicycleRequest.uploadFile(file, 'yourpath').then((response: AxiosResponse<any>) => {
          ToastUtil.hide();
          alert(response.data.img_url);
          ToastUtil.showMessage('上传成功');
          console.log(response);
        }).catch((error: AxiosError) => {
          ToastUtil.hide();
          ToastUtil.showMessage('上传失败');
          console.log(error);
        });
      }, (error: any) => {
        console.log(JSON.stringify(error));
      }, () => {
        console.log('on complete');
      });
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });

  }

  public onOpenAMapClick(): void {
    this.$router.push({path: '/amap-demo'});
  }

  public onGetAuthCode(): void {
   /* WeixinRequest.getAuthCode('http://shared-bicycle-sy-d.parkone.cn/suggestion#/main-page').then((response: AxiosResponse) => {
      console.log(response);
    }).catch((error: AxiosError) => {
      console.log(error);
    });*/
    window.location.href = environment.SHARED_BICYCLE_SERVE_URL + 'wx/connect/oauth2/authorize?redirect_uri=' + encodeURIComponent('http://shared-bicycle-sy-d.parkone.cn/suggestion#/main-page');
  }

  public base64 = '';

  public afterRead(file: any) {
    this.base64 = file.content;
    // BicycleRequest.uploadFile(file.file, 'test');

    let waterText = '提交人:' + LocalStorageAgent.getUserInfo()!.realname + '\n'
      + '事件时间:' + TimeUtil.getDateYYYYMMDDHHMMSS(new Date().getTime()) + '\n'
      + '事件地点' + ' 测试';
    WaterMarkUtil.imgAddWaterText(this.base64, waterText, function (resultData: string) {
      let timeStamp: number = new Date().getTime();
      let fileName = 'regulator_event_' + timeStamp + ".png";
      let file = ImageUtil.dataURLtoFile(resultData, fileName);
      BicycleRequest.uploadFile(file, 'regulator_event').then((response: AxiosResponse<any>) => {
        ToastUtil.showMessage('上传成功');
        console.log(response.data.img_url)
      }).catch((error: AxiosError) => {
        ToastUtil.showMessage('上传失败');
      });
    });
  }
}
