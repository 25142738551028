import {Component, Vue} from "vue-property-decorator";
import {Button, Field, Form} from "vant";
import UserInfo from "@/biz/user-info";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import {ComponentDomain} from "@/domain/component-domain";
import {UserRequest} from "@/request/UserRequest";
import {ToastUtil} from "@/util/toast-util";
import {DialogUtil} from "@/util/dialog-util";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {AxiosError, AxiosResponse} from "axios";

@Component({
  name: ComponentDomain.MAIN,
  components: {[Button.name]: Button, [Form.name]: Form, [Field.name]: Field}
})

export default class Main extends Vue {

  private hasPermission = false;
  private hasCityPermission = false;
  private hasThirdPermission = false;

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
  }

  private mounted() {
    //   var VConsole = require('vconsole');
    // var vConsole = new VConsole();
    const userInfo = LocalStorageAgent.getUserInfo();
    let userTypes;
    if (userInfo) {
      userTypes = userInfo.user_types;
      this.hasPermission = UserInfo.hasNormalPermission(userTypes);
      this.hasCityPermission = UserInfo.hasCityPermission(userTypes);
      this.hasThirdPermission = UserInfo.hasThirdPartPermission(userTypes);
    }
  }

  public onInformationClick() {
    this.$router.push({path: '/statistics'})
  }

  public onSuperviseClick() {
    this.$router.push({path: '/supervise-event'})
  }

  public onSuggestionClick() {
    this.$router.push({path: '/public-advice'})
  }

  public onMeClick() {
    this.$router.push({path: '/user'})
  }

  public onScanBicycleClick() {
    this.$router.push({path: '/scan-record-bicycle'})
  }

  public onAssessmentRankingClick() {
    this.$router.push({path: '/assessment-ranking'})
  }

  public onRealTimeMapClick() {
    this.$router.push({path: '/real-time-map'})
  }

  public onThirdEventListClick() {
    this.$router.push({
      path: '/event-list', query: {
        fromThirdPart: 'true'
      }
    })
  }

  public onLogoutClick() {
    DialogUtil.showCallbackConfirmDialog('提示', '确定退出登录？', '', '')
      .then(() => {
          UserRequest.logout()
            .then((response: AxiosResponse) => {
              this.$router.replace({name: 'Login'});
            }).catch((error: AxiosError) => {
            let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
            if (error.response?.status === 422) {
              resError = '数据获取失败';
            }
            ToastUtil.showMessage(resError);
          });
        }
      ).catch(() => {
      console.log('on confirm catch');
    });
  }
}
