import { render, staticRenderFns } from "./supervise-event-detail.vue?vue&type=template&id=7fc731fa&scoped=true&"
import script from "./supervise-event-detail.ts?vue&type=script&lang=ts&"
export * from "./supervise-event-detail.ts?vue&type=script&lang=ts&"
import style0 from "./supervise-event-detail.less?vue&type=style&index=0&id=7fc731fa&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc731fa",
  null
  
)

export default component.exports