import {Component, Vue} from "vue-property-decorator";
import {Field} from "vant";
import ValueUtil from "../../../../util/value-util";
import {ToastUtil} from "../../../../util/toast-util";
import {UserRequest} from "../../../../request/UserRequest";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser, {EntityErrorPaser} from "../../../../http/error/HttpResErrorPaser";
import Error from "../../../../http/error/Error";
import {LocalStorageAgent} from "../../../../storage/local-storage-agent";

@Component({
  components: {
    [Field.name]: Field
  }
})
export default class ModifyPwd extends Vue {

  public oldPwd = '';
  public newPwd = '';
  public comfirmPwd = '';

  private mounted() {

  }

  public onConfirmClick() {
    if (ValueUtil.isStrEmpty(this.oldPwd)) {
      ToastUtil.showMessage("请输入旧密码");
      return;
    }
    if (ValueUtil.isStrEmpty(this.newPwd)) {
      ToastUtil.showMessage("请输入新密码");
      return;
    }
    if (ValueUtil.isStrEmpty(this.comfirmPwd)) {
      ToastUtil.showMessage("请确认新密码");
      return;
    }
    if (this.oldPwd.length < 6 || this.newPwd.length < 6) {
      ToastUtil.showMessage("密码必须6-20位");
      return;
    }
    if (this.newPwd !== this.comfirmPwd) {
      ToastUtil.showMessage("2次密码不一致");
      return;
    }
    this.modifyPwdRequest();
  }

  private modifyPwdRequest() {
    UserRequest.modifyPassword(this.oldPwd, this.newPwd)
      .then((response: AxiosResponse) => {
          ToastUtil.hide();
          LocalStorageAgent.setUserInfo(null);
        // 退回到第一页（不使用push方式，会增加history）
        const historyLength = window.history.length;
        this.$router.go(historyLength > 1 ? -historyLength + 1 : -1);
        }
      ).catch((error: AxiosError) => {
      ToastUtil.hide();
      // 解析422
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
        public parseUnprocessableEntity(error: Error): string {
          if (error != null) {
            if ('old_password' == error.field && 'invalid' == error.code) {
              return '原始密码输入错误！';
            } else if ('password' == error.field && 'invalid' == error.code)
              return '新密码不能与原始密码相同！'
          }
          return '修改密码失败';
        }
      });
      ToastUtil.showMessage(resError);
    });
  }
}
