import {Component, Vue} from "vue-property-decorator";
import {Button, Cell, List} from "vant";
import RegulatorEvent from "@/biz/regulator-event";
import SuperviseEventRequest from "@/request/supervise-event-request";
import {AxiosError, AxiosResponse} from "axios";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import TimeUtil from "@/util/time-util";
import RecommendEvent from "@/biz/recommend-event";
import {AdviceRequest} from "@/request/advice-request";

@Component({
  components: {[Button.name]: Button, [List.name]: List, [Cell.name]: Cell},
  filters: {
    dataFormat(value: number): string {
      return TimeUtil.getDateYYYYMMDDHHMMSS(value).replace(' ', '\r\n');
    },
    eventType(eventCategory: number): string {
      switch (eventCategory) {
        case 1:
          return '停放点不合理';
        case 2:
          return '建议增加停放点';
        default:
          return '';
      }
    },
    eventStatus(dealStatus: number): string {
      switch (dealStatus) {
        case 1:
          return '已提交';
        case 2:
          return '已采纳';
        case 3:
          return '已驳回';
        default:
          return '';
      }
    }
  }
})

export default class RecommendList extends Vue {

  public readonly pageSize: number = 20;
  public recommendList: Array<RecommendEvent> = [];
  public loading: boolean = false;
  public finished: boolean = false;
  private nextUrl: string = '';
  public list: Array<number> = [];

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
  }

  private mounted() {
    console.log('mounted');
  }

  private requestEventList(nextUrl: string) {
    const userInfo = LocalStorageAgent.getUserInfo();
    if (userInfo == null) {
      return;
    }
    if (!nextUrl) {
      ToastUtil.showLoading('数据加载中...')
    }
    AdviceRequest.getRecommendEvents(this.pageSize, undefined, nextUrl)
      .then((response: AxiosResponse) => {
        ToastUtil.hide();
        if (!nextUrl || nextUrl === '') {
          this.recommendList = [];
          document.body.scrollIntoView();
        }
        const events = HttpResponseUtil.parseJSONArray(RecommendEvent, response.data);
        if (events && events.length > 0) {
          this.recommendList.push(...events);
        }
        let has = response.headers.hasOwnProperty("link");
        if (has) {
          this.nextUrl = HttpResponseUtil.getHeaderLink(response.headers.link);
        } else {
          this.nextUrl = '';
        }
        // 加载状态结束
        this.loading = false;
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true;
        } else {
          this.finished = false;
        }
      }).catch((error: AxiosError) => {
      ToastUtil.hide();
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
      this.loading = false;
      if (!this.nextUrl || this.nextUrl === '') {
        this.finished = true;
      } else {
        this.finished = false;
      }
    });
  }

  public onLoad() {
    this.requestEventList(this.nextUrl);
  }

  public refresh() {
    this.requestEventList('');
  }

  public onDetailClick(event: RecommendEvent) {
    this.$router.push({
      path: '/recommend-detail',
      query: {
        recommendEventId: event.recommend_event_id
      }
    })
  }

  public onRefreshClick() {
    this.refresh();
  }
}
