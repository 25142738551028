export default class UserInfo {
  public username!: string;
  public realname?: string;
  public telephone?: string;
  public user_types!: number[];
  public company_id!: string;
  public company_type!: string;
  public is_superuser!: boolean;
  public region_code!: string;
  public street_code!: string;

  /**
   * 是否有权限
   * @param userTypes
   */
  public static hasPermission(userTypes: number[]): boolean {
    let hasPermission = false;
    userTypes.find((userType) => {
      // 4,统筹监管 5,区监管 6,街道监管,8三方
      if (userType === 4 || userType === 5 || userType === 6 || userType === 8) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  /**
   * 是否有权限
   * @param userTypes
   */
  public static hasNormalPermission(userTypes: number[]): boolean {
    let hasPermission = false;
    userTypes.find((userType) => {
      // 4,统筹监管 5,区监管 6,街道监管
      if (userType === 4 || userType === 5 || userType === 6) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  /**
   * 是否有权限
   * @param userTypes
   */
  public static hasThirdPartPermission(userTypes: number[]): boolean {
    let hasPermission = false;
    userTypes.find((userType) => {
      //8三方
      if (userType === 8) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }


  /**
   * 是否有权限
   * @param userTypes
   */
  public static hasCityPermission(userTypes: number[]): boolean {
    let hasPermission = false;
    userTypes.find((userType) => {
      // 4,统筹监管 5,区监管 6,街道监管
      if (userType === 4) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  /**
   * 是否有权限
   * @param userTypes
   */
  public static isRegionPermission(userTypes: number[]): boolean {
    let hasPermission = false;
    userTypes.find((userType) => {
      // 4,统筹监管 5,区监管 6,街道监管
      if (userType === 5 || userType === 6) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }
}
