import RegionInfoDomain from "@/domain/region-info-domain";

export class RegionCodeUtil {

  /**
   * 根据code返回name
   * @param {string} regionCode
   * @returns {string}
   */
  public static getRegionName(regionCode: string): string {
    let regionName = '';
    RegionInfoDomain.REGIONS.find((item: any) => {
      if (item.code === regionCode) {
        regionName = item.name;
      }
    });
    return regionName;
  }
}
